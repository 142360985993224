<template>

	<layout :include_header="true">

		<template v-slot:header-center>

			<main_nav />

			<workspace_nav v-if="auth_user && ( workspace && workspace.is_created )" />

		</template>

		<template v-slot:header-end>

			<support_link />

		</template>

		<template v-slot:main-body>
			
			<div class="w-100 h-100 d-flex">

				<div class="setup-chat h-100 border-end p-4 overflow-auto" style="flex: 0 0 400px;">
					
					<div>						

						<chat_message content="Hey there, Miles! My name is Bella and I'm going to assist you in setting up your site." type="1" author="1" />

						<chat_message content="I can see that you are re-designing an existing website. I have crawled the site to gain a better understanding of who your client is, what they do and how they do it differently." type="1" author="1" />

						<chat_message content="In order for me to better assist you I'm going to ask you to check over the insights I have gathered to make sure they are correct. Feel free to edit the information and don't worry, you can always change this later." type="1" author="1" />

						<chat_message content="Ready to get started?" type="1" author="1" />

						<chat_message content="Yeah, let's do it!" type="2" author="4" />

						<chat_message content="Great! First of all let's check your clients contact information. Let me know once you're happy to move on to the next step." type="1" author="1" />

					</div>

				</div>

				<div class="setup-content">
					<router-view />
				</div>

			</div>

		</template>

	</layout>

</template>

<script>
import { mapGetters } from 'vuex';
import layout from '@/components/layout'
import main_nav from '@/components/main_nav'
import support_link from '@/components/support_link'
import workspace_nav from '@/components/workspace_nav'
import chat_message from '@/components/chat_message'
import workspace_service from '@/services/workspace_service'
import site_service from '@/services/site_service'

export default {
	name: 'site.add',

	components: {
		layout,
		main_nav,
		support_link,
		workspace_nav,
		chat_message
	},

	data() {
		return {
			loading: false,
			workspace_loading: true,
			workspace: null,
			name: null,
			url: null,
			locale: 'en_GB',
			error: {}
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		...mapGetters( 'locales', ['store_locales'] ),

	},

	mounted()
	{
		this.get_workspace()
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async get_workspace()
		{
			this.reset_error()

			workspace_service.show( this.$route.params.workspace_id ).then(( response ) => {
				this.workspace = response.data

				if ( !this.workspace.is_created && !this.workspace.error_message ) {
					setTimeout(() => {
						this.get_workspace()
					}, 300000 )
				}
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.workspace_loading = false
			})
		},

		async submit()
		{
			this.loading = true 

			this.reset_error()

			site_service.store( this.$route.params.workspace_id, {
				name: this.name,
				url: this.url,
				locale: this.locale
			}).then(( response ) => {
				this.$router.push( { name: 'site.show', params: { site_id: response.data.id } } )
			}).catch( ( error ) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		}
	}
}
</script>

<style>

</style>
